import React from 'react';
import { Box, CircularProgress, Alert, Typography, alpha } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ProjectCard } from './ProjectCard';
import { motion } from 'framer-motion';

const MotionGrid = motion(Grid);

export const ProjectList = ({ projects, isLoading, error }) => {
    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8,
                    gap: 2
                }}
            >
                <CircularProgress size={40} thickness={4} />
                <Typography variant="body1" color="text.secondary">
                    Loading your projects...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Alert
                severity="error"
                sx={{
                    mb: 2,
                    borderRadius: 2,
                    '& .MuiAlert-message': {
                        fontSize: '0.95rem'
                    }
                }}
            >
                {error.message || 'Failed to load projects'}
            </Alert>
        );
    }

    const projectsList = Array.isArray(projects?.projects) ? projects.projects : [];

    if (projectsList.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 8,
                    gap: 2,
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6),
                    borderRadius: 4,
                    border: '2px dashed',
                    borderColor: 'divider'
                }}
            >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                    No Projects Yet
                </Typography>
                <Typography variant="body1" color="text.secondary" align="center" sx={{ maxWidth: 400 }}>
                    Create your first project to get started. You can manage all your projects from here.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(min(350px, 100%), 1fr))',
                gap: 3,
                width: '100%'
            }}
        >
            {projectsList.map((project, index) => (
                <motion.div
                    key={project.projectId}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                    <ProjectCard project={project} />
                </motion.div>
            ))}
        </Box>
    );
};