import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const float = keyframes`
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(var(--x), var(--y));
    }
`;

const Container = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 2,
    pointerEvents: 'none',
});

const Dot = styled('div')(({ size, top, left, color, delay }) => ({
    position: 'absolute',
    width: 1,
    height: 1,
    backgroundColor: 'transparent',
    boxShadow: `0 0 ${size * 4}px ${size * 3}px ${color}`,
    borderRadius: '100%',
    top: `${top}%`,
    left: `${left}%`,
    opacity: 0.1,
    '--x': `${Math.random() * 60 - 10}px`,
    '--y': `${Math.random() * 60 - 10}px`,
    animation: `${float} ${Math.random() *6 + 4}s ease-in-out infinite`,
    animationDelay: `${delay}s`,
}));

const ModernDots = () => {
    const [dots, setDots] = useState([]);

    useEffect(() => {
        const colors = [
            'rgb(195,255,188)',  // Beyaz
            'rgb(248,171,198)',  // Açık mavi
            'rgb(159,224,244)',  // Gökyüzü mavisi
        ];

        const newDots = Array.from({ length: 100 }, (_, i) => ({
            id: i,
            size: Math.random() * (2 - 0.5) + 1, // 1-3px arası
            top: Math.random() * 100,
            left: Math.random() * 100,
            color: colors[Math.floor(Math.random() * colors.length)],
            delay: Math.random() * 2,
        }));

        setDots(newDots);
    }, []);

    return (
        <Container>
            {dots.map((dot) => (
                <Dot
                    key={dot.id}
                    size={dot.size}
                    top={dot.top}
                    left={dot.left}
                    color={dot.color}
                    delay={dot.delay}
                />
            ))}
        </Container>
    );
};

export default ModernDots;