export const API_CONFIG = {
    BASE_URL: process.env.REACT_APP_API_URL || 'https://api.fluxion.smartfinger.com',
    TIMEOUT: 30000,
    CREDENTIALS: 'include'
};

export const APP_CONFIG = {
    APP_NAME: 'Fluxion Console',
    VERSION: '1.0.0'
};

export const ENDPOINTS = {
    AUTH: {
        LOGIN: '/auth/signin',
        REGISTER: '/auth/signup',
        LOGOUT: '/auth/signout'
    },
    PROJECTS: {
        LIST: '/projects',
        CREATE: '/projects',
        GET: (id) => `/projects/${id}`,
        UPDATE: (id) => `/projects/${id}`,
        DELETE: (id) => `/projects/${id}`,
        ROTATECREDENTIALS: (id) => `/projects/${id}/credentials/rotate`
    },
    PROFILE: {
        GET: '/profile',
        UPDATE: '/profile'
    }
};