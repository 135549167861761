import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    Typography, Divider, Box, Button, CircularProgress } from '@mui/material';
import { Code as CodeIcon } from '@mui/icons-material';
import { useProjects } from '../../../app/hooks/useProjects';

export const RecentProjects = () => {
    const navigate = useNavigate();
    const { projects = [], isLoading } = useProjects();

    if (isLoading) {
        return (
            <Paper sx={{ p: 3, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    // Projelerin ilk 5'ini al veya boş array kullan
    const recentProjects = Array.isArray(projects) ? projects.slice(0, 5) : [];

    return (
        <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Recent Projects</Typography>
                <Button size="small" onClick={() => navigate('/projects')}>
                    View All
                </Button>
            </Box>

            {recentProjects.length === 0 ? (
                <Typography color="text.secondary" sx={{ textAlign: 'center', py: 3 }}>
                    No projects found
                </Typography>
            ) : (
                <List disablePadding>
                    {recentProjects.map((project, index) => (
                        <React.Fragment key={project.projectId || index}>
                            {index > 0 && <Divider />}
                            <ListItem
                                button
                                onClick={() => navigate(`/projects/${project.projectId}`)}
                                sx={{ py: 1.5 }}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.light' }}>
                                        <CodeIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={project.name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography component="span" variant="body2" color="text.primary">
                                                {project.apiCalls || 0} API calls
                                            </Typography>
                                            {" — "}
                                            {project.lastActivity || 'No recent activity'}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </React.Fragment>
                    ))}
                </List>
            )}
        </Paper>
    );
};