import React, { useState } from 'react';
import { Box, IconButton, useTheme, alpha } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { LoginForm } from '../components/LoginForm';
import { RegisterForm } from '../components/RegisterForm';
import { Logo } from './LogoText';
import { motion, AnimatePresence } from 'framer-motion';
import backgroundImageUrl from '../../../assets/images/background.jpg';
import ModernDots from "./GlowingDots";

const MotionBox = motion(Box);

export const AuthPage = ({ backgroundImage = backgroundImageUrl }) => {
    const theme = useTheme();
    const [isRegistering, setIsRegistering] = useState(false);

    return (
        <Box
            sx={{
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 0,
                },
                '&::after': {
                    content: '""',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: alpha('#000000', 0.6),
                    zIndex: 1,
                }
            }}
        >
            <ModernDots />

            {/* Left Side Container including Logo and Welcome Message */}
            <Box
                sx={{
                    position: 'relative',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: { xs: '100%', md: 'calc(100% - 500px)' },
                    zIndex: 2,
                }}
            >
                {/* Logo */}
                <Box
                    sx={{
                        position: { xs: 'fixed', md: 'absolute' },
                        top: { xs: 20, md: 40 },
                        left: { xs: '50%', md: 40 },
                        transform: {
                            xs: 'translateX(-50%)',
                            md: 'none'
                        },
                        width: { xs: '100%', md: 'calc(100% - 80px)' }, // Account for padding
                        zIndex: 10
                    }}
                >
                    <Logo />
                </Box>

                {/* Welcome Message */}
                <MotionBox
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 6,
                        color: 'white',
                        minHeight: '100vh',
                        '& h2': {
                            fontSize: { md: '3.5rem', lg: '4rem' },
                            fontWeight: 800,
                            textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                            mb: 3
                        },
                        '& h5': {
                            fontSize: { md: '1.5rem', lg: '1.75rem' },
                            fontWeight: 500,
                            opacity: 0.9,
                            lineHeight: 1.5,
                            textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                        }
                    }}
                >
                    <AnimatePresence mode="wait">
                        <MotionBox
                            key={isRegistering ? 'register' : 'login'}
                            initial={{ opacity: 0, x: 0 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 0 }}
                            transition={{ duration: 0.15 }}
                        >
                            {isRegistering ? (
                                <>
                                    <h2>Join Our Community</h2>
                                    <h5>
                                        Begin your journey with us and unlock the power of seamless development.
                                        Join thousands of developers building the future.
                                    </h5>
                                </>
                            ) : (
                                <>
                                    <h2>Welcome Back</h2>
                                    <h5>
                                        Continue your development journey with our powerful platform.
                                        Your projects await.
                                    </h5>
                                </>
                            )}
                        </MotionBox>
                    </AnimatePresence>
                </MotionBox>
            </Box>

            {/* Right Side - Auth Forms */}
            <MotionBox
                sx={{
                    width: { xs: '100%', md: '500px' },
                    minHeight: '100vh',
                    backgroundColor: alpha(theme.palette.background.paper, theme.palette.mode === 'dark' ? 0.9 : 0.95),
                    backdropFilter: 'blur(10px)',
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    boxShadow: theme.palette.mode === 'dark'
                        ? '0 0 40px rgba(0,0,0,0.5)'
                        : '0 0 40px rgba(0,0,0,0.1)',
                    mt: { xs: '80px', md: 0 }
                }}
            >
                {/* Back Button - Mobile only */}
                <AnimatePresence>
                    {isRegistering && (
                        <MotionBox
                            initial={{ opacity: 0, x: 0 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 0 }}
                            transition={{ duration: 0.1 }}
                            sx={{
                                position: 'absolute',
                                top: 20,
                                left: 20,
                                zIndex: 10,
                                display: { md: 'none' }
                            }}
                        >
                            <IconButton
                                onClick={() => setIsRegistering(false)}
                                sx={{
                                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                                    '&:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.2)
                                    }
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </MotionBox>
                    )}
                </AnimatePresence>

                {/* Form Container */}
                <Box
                    sx={{
                        flex: 1,
                        px: { xs: 3, sm: 4, md: 6 },
                        py: { xs: 4, sm: 6 },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <AnimatePresence mode="wait">
                        <MotionBox
                            key={isRegistering ? 'register-form' : 'login-form'}
                            initial={{ opacity: 0, x: 0 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.1
                            }}
                            style={{ width: '100%' }}
                        >
                            {isRegistering ? (
                                <RegisterForm onBackToLogin={() => setIsRegistering(false)} />
                            ) : (
                                <LoginForm onRegisterClick={() => setIsRegistering(true)} />
                            )}
                        </MotionBox>
                    </AnimatePresence>
                </Box>
            </MotionBox>
        </Box>
    );
};