import React, { useState } from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './app/context/AuthContext';
import { getTheme } from './app/theme';
import { MainLayout } from './app/components/layouts/MainLayout';
import { AuthPage } from './features/auth/pages/AuthPage';
import { DashboardPage } from './features/dashboard/pages/DashboardPage';
import { ProjectsPage } from './features/projects/pages/ProjectsPage';
import { ProjectDetailPage } from './features/projects/pages/ProjectDetailPage';
import { ProfilePage } from './features/profile/pages/ProfilePage';
import { SettingsPage } from './features/profile/pages/SettingsPage';
import PrivateRoute from './app/components/common/PrivateRoute';

function App() {
    const [mode, setMode] = useState('light');
    const theme = getTheme(mode);

    const toggleTheme = () => {
        setMode(prevMode => prevMode === 'light' ? 'dark' : 'light');
    };

    return (
        <>
            <CssBaseline />
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<AuthPage />} />
                        <Route path="/register" element={<AuthPage />} />
                        <Route path="/" element={
                            <PrivateRoute>
                                <MainLayout toggleTheme={toggleTheme} />
                            </PrivateRoute>
                        }>
                            <Route index element={<DashboardPage />} />
                            <Route path="dashboard" element={<DashboardPage />} />
                            <Route path="projects" element={<ProjectsPage />} />
                            <Route path="projects/:id" element={<ProjectDetailPage />} />
                            <Route path="profile" element={<ProfilePage />} />
                            <Route path="settings" element={<SettingsPage />} />
                        </Route>
                        <Route
                            path="*"
                            element={<Navigate to="/login" replace />}
                        />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </>
    );
}

export default App;