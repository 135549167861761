import React from 'react';
import {
    Paper,
    Box,
    Typography,
    Avatar
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'translateY(-4px)'
    }
}));

export const StatisticsCard = ({ title, value, change, icon, color }) => {
    const isPositive = change?.startsWith('+');

    return (
        <StyledPaper>
            <Avatar
                sx={{
                    backgroundColor: `${color}15`,
                    color: color,
                    width: 52,
                    height: 52,
                    mr: 2
                }}
            >
                {icon}
            </Avatar>
            <Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                    {value}
                </Typography>
                {change && (
                    <Typography
                        variant="body2"
                        color={isPositive ? 'success.main' : 'error.main'}
                    >
                        {change} from last month
                    </Typography>
                )}
            </Box>
        </StyledPaper>
    );
};