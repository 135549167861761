import React, { useState } from 'react';
import {
    Paper,
    Typography,
    Box,
    TextField,
    IconButton,
    Button,
    Alert,
    Tooltip,
    useTheme,
    alpha,
    CircularProgress
} from '@mui/material';
import {
    ContentCopy as CopyIcon,
    Refresh as RefreshIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Key as KeyIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { projectService } from '../../../app/services/projectService';
import { motion } from 'framer-motion';

const MotionPaper = motion(Paper);
const MotionBox = motion(Box);

export const ApiCredentials = ({ project }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [showSecret, setShowSecret] = useState(false);
    const queryClient = useQueryClient();

    const credentials = project?.credentials || {};
    const apiKey = credentials.apiKey || project.apiKey || 'N/A';
    const apiSecret = credentials.apiSecret || project.apiSecret || 'N/A';
    const projectId = project?.projectId;
    const isRotateDisabled = !projectId;

    const rotateMutation = useMutation({
        mutationFn: (id) => projectService.rotateCredentials(id),
        onSuccess: () => {
            queryClient.invalidateQueries(['project', projectId]);
            enqueueSnackbar('API credentials rotated successfully', {
                variant: 'success',
                autoHideDuration: 3000
            });
        },
        onError: (error) => {
            enqueueSnackbar(error.message || 'Failed to rotate credentials', {
                variant: 'error',
                autoHideDuration: 5000
            });
        }
    });

    const handleCopy = async (text, label) => {
        if (text && text !== 'N/A') {
            try {
                await navigator.clipboard.writeText(text);
                enqueueSnackbar(`${label} copied to clipboard`, {
                    variant: 'success',
                    autoHideDuration: 2000
                });
            } catch (error) {
                // Fallback for older browsers
                const textArea = document.createElement('textarea');
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand('copy');
                    enqueueSnackbar(`${label} copied to clipboard`, { variant: 'success' });
                } catch (err) {
                    enqueueSnackbar('Failed to copy to clipboard', { variant: 'error' });
                }
                document.body.removeChild(textArea);
            }
        }
    };

    const handleRotateCredentials = () => {
        if (!projectId) {
            enqueueSnackbar('Project ID not found', {
                variant: 'error',
                autoHideDuration: 3000
            });
            return;
        }

        if (window.confirm('Are you sure you want to rotate API credentials? This will invalidate the current credentials.')) {
            rotateMutation.mutate(projectId);
        }
    };

    return (
        <MotionPaper
            elevation={0}
            sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 3,
                overflow: 'hidden'
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            <Box
                sx={{
                    p: 3,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    bgcolor: alpha(theme.palette.background.paper, 0.6)
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <KeyIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="h6" fontWeight={600}>
                            API Credentials
                        </Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        startIcon={rotateMutation.isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
                        onClick={handleRotateCredentials}
                        disabled={isRotateDisabled || rotateMutation.isLoading}
                        sx={{
                            borderRadius: 2,
                            textTransform: 'none',
                            px: 2
                        }}
                    >
                        {rotateMutation.isLoading ? 'Rotating...' : 'Rotate Credentials'}
                    </Button>
                </Box>
                <Alert
                    severity="warning"
                    sx={{
                        borderRadius: 2,
                        '.MuiAlert-icon': {
                            alignItems: 'center'
                        }
                    }}
                >
                    Keep your API credentials secure and never share them in public repositories or client-side code.
                </Alert>
            </Box>

            <Box sx={{ p: 3 }}>
                <MotionBox
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3
                    }}
                >
                    <TextField
                        fullWidth
                        label="API Key"
                        value={apiKey}
                        InputProps={{
                            readOnly: true,
                            sx: {
                                fontFamily: 'monospace',
                                fontSize: '0.9rem',
                                borderRadius: 2
                            },
                            endAdornment: (
                                <Tooltip title="Copy API Key" arrow>
                                    <IconButton
                                        onClick={() => handleCopy(apiKey, 'API Key')}
                                        disabled={apiKey === 'N/A'}
                                        edge="end"
                                        size="small"
                                        sx={{
                                            mr: 1,
                                            color: theme.palette.primary.main
                                        }}
                                    >
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }}
                    />

                    <TextField
                        fullWidth
                        label="API Secret"
                        value={showSecret ? apiSecret : '••••••••••••••••'}
                        type={showSecret ? 'text' : 'password'}
                        InputProps={{
                            readOnly: true,
                            sx: {
                                fontFamily: 'monospace',
                                fontSize: '0.9rem',
                                borderRadius: 2
                            },
                            endAdornment: (
                                <Box sx={{ display: 'flex', gap: 0.5 }}>
                                    <Tooltip title={showSecret ? "Hide Secret" : "Show Secret"} arrow>
                                        <IconButton
                                            onClick={() => setShowSecret(!showSecret)}
                                            disabled={apiSecret === 'N/A'}
                                            size="small"
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            {showSecret ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Copy API Secret" arrow>
                                        <IconButton
                                            onClick={() => handleCopy(apiSecret, 'API Secret')}
                                            disabled={apiSecret === 'N/A'}
                                            edge="end"
                                            size="small"
                                            sx={{
                                                mr: 1,
                                                color: theme.palette.primary.main
                                            }}
                                        >
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )
                        }}
                    />
                </MotionBox>
            </Box>

            {rotateMutation.isLoading && (
                <Alert
                    severity="info"
                    sx={{
                        mx: 3,
                        mb: 3,
                        borderRadius: 2
                    }}
                >
                    Rotating credentials, please wait...
                </Alert>
            )}
        </MotionPaper>
    );
};