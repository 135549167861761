import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Container,
    Paper,
    Typography,
    Box,
    Tabs,
    Tab,
    IconButton,
    CircularProgress,
    Alert,
    useTheme,
    alpha,
    Tooltip
} from '@mui/material';
import {
    Edit as EditIcon,
    Refresh as RefreshIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { projectService } from '../../../app/services/projectService';
import { ProjectForm } from '../components/ProjectForm';
import { ApiCredentials } from '../components/ApiCredentials';
import { ProjectStats } from '../components/ProjectStats';
import { motion } from 'framer-motion';

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);

export const ProjectDetailPage = () => {
    const theme = useTheme();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);

    const {
        data: response,
        isLoading,
        error,
        refetch
    } = useQuery({
        queryKey: ['project', id],
        queryFn: () => projectService.getProject(id),
        enabled: !!id
    });

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '60vh',
                    gap: 2
                }}
            >
                <CircularProgress size={40} />
                <Typography variant="body1" color="text.secondary">
                    Loading project details...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ mt: 3 }}>
                <Alert
                    severity="error"
                    sx={{
                        borderRadius: 2,
                        '& .MuiAlert-message': { fontSize: '0.95rem' }
                    }}
                >
                    Failed to load project: {error.message}
                </Alert>
            </Container>
        );
    }

    const projectData = response?.data?.project || response?.project || response || {};

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleEditProject = async (updatedData) => {
        try {
            await projectService.updateProject(id, updatedData);
            await refetch();
            setIsEditFormOpen(false);
        } catch (error) {
            console.error('Failed to update project:', error);
        }
    };

    return (
        <MotionContainer
            maxWidth="lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            sx={{ py: { xs: 2, sm: 3 } }}
        >
            <MotionPaper
                elevation={0}
                sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
            >
                {/* Header Section */}
                <Box sx={{
                    p: 3,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8)
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <IconButton
                            onClick={() => window.history.back()}
                            sx={{
                                mr: 2,
                                color: 'text.secondary',
                                '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.08) }
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h5" fontWeight={600}>
                                {projectData.name || 'Project Details'}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="text.secondary"
                                sx={{ mt: 0.5 }}
                            >
                                {projectData.description || 'No description available'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Tooltip title="Refresh Data">
                                <IconButton
                                    onClick={() => refetch()}
                                    sx={{
                                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                                        '&:hover': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.08)
                                        }
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit Project">
                                <IconButton
                                    onClick={() => setIsEditFormOpen(true)}
                                    sx={{
                                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                                        '&:hover': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.08)
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTab-root': {
                                minHeight: 48,
                                fontWeight: 500,
                                fontSize: '0.95rem',
                                textTransform: 'none',
                                '&.Mui-selected': {
                                    color: 'primary.main'
                                }
                            },
                            '& .MuiTabs-indicator': {
                                height: 3,
                                borderRadius: '3px 3px 0 0'
                            }
                        }}
                    >
                        <Tab label="Overview" />
                        <Tab label="API Credentials" />
                        <Tab label="Settings" />
                    </Tabs>
                </Box>

                {/* Content Section */}
                <Box sx={{ p: 3 }}>
                    {activeTab === 0 && <ProjectStats project={projectData} />}
                    {activeTab === 1 && <ApiCredentials project={projectData} />}
                    {activeTab === 2 && (
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 3
                            }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontWeight: 600,
                                    color: 'text.primary',
                                    mb: 2
                                }}
                            >
                                Project Settings
                            </Typography>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                                    gap: 2
                                }}
                            >
                                {Object.entries(projectData.settings || {}).map(([key, value]) => (
                                    <Alert
                                        key={key}
                                        severity={value ? "success" : "info"}
                                        sx={{
                                            borderRadius: 2,
                                            '& .MuiAlert-message': {
                                                fontSize: '0.95rem'
                                            }
                                        }}
                                    >
                                        {`${key.replace(/([A-Z])/g, ' $1').trim()}: ${value ? 'Enabled' : 'Disabled'}`}
                                    </Alert>
                                ))}
                            </Box>
                        </Paper>
                    )}
                </Box>
            </MotionPaper>

            <ProjectForm
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
                initialValues={projectData}
                onSubmit={handleEditProject}
                isEdit={true}
            />
        </MotionContainer>
    );
};