import api from '../utils/axios';
import { ENDPOINTS } from '../config/constants';

export const projectService = {
    getProjects: async () => {
        const response = await api.get(ENDPOINTS.PROJECTS.LIST);
        return response.data;
    },

    getProject: async (id) => {
        const response = await api.get(ENDPOINTS.PROJECTS.GET(id));
        return response.data;
    },

    createProject: async (data) => {
        const response = await api.post(ENDPOINTS.PROJECTS.CREATE, data);
        return response.data;
    },

    updateProject: async (id, data) => {
        const response = await api.put(ENDPOINTS.PROJECTS.UPDATE(id), data);
        return response.data;
    },

    deleteProject: async (id) => {
        const response = await api.delete(ENDPOINTS.PROJECTS.DELETE(id));
        return response.data;
    },
    rotateCredentials: async (id) => {
        const response = await api.post(ENDPOINTS.PROJECTS.ROTATECREDENTIALS(id));
        return response.data;
    }
};