import React from 'react';
import {
    Container,
    Grid,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import { StatisticsCard } from '../components/StatisticsCard';
import { RecentProjects } from '../components/RecentProjects';
import { ActivityChart } from '../components/ActivityChart';
import { ProjectsOverview } from '../components/ProjectsOverview';
import {
    TrendingUp,
    Storage,
    Speed,
    CloudDone
} from '@mui/icons-material';

export const DashboardPage = () => {
    const theme = useTheme();

    const statistics = [
        {
            title: 'Total API Calls',
            value: '2.4M',
            change: '+14%',
            icon: <TrendingUp />,
            color: theme.palette.primary.main
        },
        {
            title: 'Storage Used',
            value: '1.2 TB',
            change: '+6%',
            icon: <Storage />,
            color: theme.palette.secondary.main
        },
        {
            title: 'Avg Response Time',
            value: '75ms',
            change: '-8%',
            icon: <Speed />,
            color: theme.palette.success.main
        },
        {
            title: 'Active Projects',
            value: '12',
            change: '+2',
            icon: <CloudDone />,
            color: theme.palette.info.main
        }
    ];

    return (
        <Container maxWidth="xl">
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Dashboard
                </Typography>
                <Typography color="text.secondary">
                    Welcome back! Here's what's happening with your projects.
                </Typography>
            </Box>

            {/* Statistics Cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                {statistics.map((stat) => (
                    <Grid item xs={12} sm={6} md={3} key={stat.title}>
                        <StatisticsCard {...stat} />
                    </Grid>
                ))}
            </Grid>

            {/* Charts and Recent Activity */}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <ActivityChart />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <RecentProjects />
                </Grid>
                <Grid item xs={12}>
                    <ProjectsOverview />
                </Grid>
            </Grid>
        </Container>
    );
};