import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    useTheme,
    alpha,
    Alert,
    CircularProgress
} from '@mui/material';
import {
    Visibility,
    VisibilityOff,
    Person as PersonIcon,
    Email as EmailIcon,
    Lock as LockIcon,
    HowToReg as RegisterIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../app/hooks/useAuth';

const MotionBox = motion(Box);

const validationSchema = Yup.object({
    developerName: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const RegisterForm = ({ onBackToLogin }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { register } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            developerName: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setStatus }) => {
            try {
                await register({
                    developerName: values.developerName,
                    email: values.email,
                    password: values.password
                });
                navigate('/dashboard');
            } catch (error) {
                setStatus({ error: error.message });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const inputStyles = {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            '&:hover': {
                backgroundColor: alpha(theme.palette.background.paper, 0.9),
            }
        }
    };

    return (
        <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
        >
            <Box sx={{ mb: 4, textAlign: 'center' }}>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent'
                    }}
                >
                    Create Account
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Join us and start building amazing projects
                </Typography>
            </Box>

            {formik.status?.error && (
                <Alert
                    severity="error"
                    sx={{
                        mb: 3,
                        borderRadius: 2,
                        backgroundColor: alpha(theme.palette.error.main, 0.1)
                    }}
                >
                    {formik.status.error}
                </Alert>
            )}

            <Box component="form" onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    name="developerName"
                    label="Developer Name"
                    margin="normal"
                    value={formik.values.developerName}
                    onChange={formik.handleChange}
                    error={formik.touched.developerName && Boolean(formik.errors.developerName)}
                    helperText={formik.touched.developerName && formik.errors.developerName}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon sx={{ color: theme.palette.primary.main }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={inputStyles}
                />

                <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon sx={{ color: theme.palette.primary.main }} />
                            </InputAdornment>
                        ),
                    }}
                    sx={inputStyles}
                />

                <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon sx={{ color: theme.palette.primary.main }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={inputStyles}
                />

                <TextField
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    margin="normal"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon sx={{ color: theme.palette.primary.main }} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={inputStyles}
                />

                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting}
                    startIcon={formik.isSubmitting ? <CircularProgress size={20} /> : <RegisterIcon />}
                    sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.2,
                        fontSize: '1rem',
                        fontWeight: 600,
                        textTransform: 'none',
                        borderRadius: 2,
                        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                        '&:hover': {
                            boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.3)}`,
                            transform: 'translateY(-1px)'
                        }
                    }}
                >
                    {formik.isSubmitting ? 'Creating Account...' : 'Create Account'}
                </Button>

                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                        Already have an account?{' '}
                        <Button
                            onClick={onBackToLogin}
                            color="primary"
                            sx={{
                                textTransform: 'none',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Sign In
                        </Button>
                    </Typography>
                </Box>

                {/* Password Requirements */}
                {(formik.touched.password || formik.values.password) && (
                    <Box
                        sx={{
                            mt: 2,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: alpha(theme.palette.primary.main, 0.05),
                            border: '1px solid',
                            borderColor: alpha(theme.palette.primary.main, 0.1)
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                        >
                            Password must contain:
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            {[
                                { check: /.{8,}/, text: 'At least 8 characters' },
                                { check: /[0-9]/, text: 'At least one number' },
                                { check: /[A-Z]/, text: 'At least one uppercase letter' },
                                { check: /[a-z]/, text: 'At least one lowercase letter' },
                                { check: /[^A-Za-z0-9]/, text: 'At least one special character' }
                            ].map((requirement, index) => (
                                <Typography
                                    key={index}
                                    variant="caption"
                                    color={
                                        requirement.check.test(formik.values.password)
                                            ? 'success.main'
                                            : 'text.secondary'
                                    }
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5,
                                        '&::before': {
                                            content: '""',
                                            width: 6,
                                            height: 6,
                                            borderRadius: '50%',
                                            backgroundColor: 'currentColor',
                                            display: 'inline-block'
                                        }
                                    }}
                                >
                                    {requirement.text}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </MotionBox>
    );
};