import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography, Box, Chip, CircularProgress } from '@mui/material';
import { useProjects } from '../../../app/hooks/useProjects';

export const ProjectsOverview = () => {
    const { projects = [], isLoading } = useProjects();

    if (isLoading) {
        return (
            <Paper sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    // Array kontrolü yap
    const projectsList = Array.isArray(projects) ? projects : [];

    return (
        <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
                Projects Overview
            </Typography>

            {projectsList.length === 0 ? (
                <Typography color="text.secondary" sx={{ textAlign: 'center', py: 3 }}>
                    No projects found
                </Typography>
            ) : (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>API Calls</TableCell>
                                <TableCell>Response Time</TableCell>
                                <TableCell>Storage Used</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectsList.map((project) => (
                                <TableRow key={project.projectId}>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body1">
                                                {project.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={project.status}
                                            size="small"
                                            color={project.status === 'ACTIVE' ? 'success' : 'default'}
                                        />
                                    </TableCell>
                                    <TableCell>{project.apiCalls || 0}</TableCell>
                                    <TableCell>{project.avgResponseTime || '0ms'}</TableCell>
                                    <TableCell>{project.storageUsed || '0 GB'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    );
};