import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

const data = [
    { name: 'Mon', calls: 4000, responseTime: 80 },
    { name: 'Tue', calls: 3000, responseTime: 75 },
    { name: 'Wed', calls: 5000, responseTime: 85 },
    { name: 'Thu', calls: 2780, responseTime: 70 },
    { name: 'Fri', calls: 4890, responseTime: 90 },
    { name: 'Sat', calls: 2390, responseTime: 65 },
    { name: 'Sun', calls: 3490, responseTime: 75 }
];

export const ActivityChart = () => {
    return (
        <Paper sx={{ p: 3, height: 400 }}>
            <Typography variant="h6" gutterBottom>
                API Activity
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
                <LineChart
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="calls"
                        stroke="#8884d8"
                        name="API Calls"
                    />
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="responseTime"
                        stroke="#82ca9d"
                        name="Response Time (ms)"
                    />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};