import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    IconButton,
    Typography,
    useTheme,
    alpha,
    Avatar,
    Grid,
    Divider
} from '@mui/material';
import {
    Close as CloseIcon,
    PhotoCamera as PhotoCameraIcon
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    company: Yup.string(),
    title: Yup.string(),
    location: Yup.string(),
    bio: Yup.string().max(500, 'Bio must be at most 500 characters'),
    socialLinks: Yup.object({
        github: Yup.string().url('Must be a valid URL'),
        linkedin: Yup.string().url('Must be a valid URL'),
        twitter: Yup.string().url('Must be a valid URL')
    })
});

export const ProfileEditDialog = ({ open, onClose, user }) => {
    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            name: user?.name || '',
            email: user?.email || '',
            company: user?.company || '',
            title: user?.title || '',
            location: user?.location || '',
            bio: user?.bio || '',
            socialLinks: {
                github: user?.socialLinks?.github || '',
                linkedin: user?.socialLinks?.linkedin || '',
                twitter: user?.socialLinks?.twitter || ''
            }
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                // Update profile logic here
                console.log('Updated values:', values);
                onClose();
            } catch (error) {
                console.error('Failed to update profile:', error);
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle
                sx={{
                    p: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8)
                }}
            >
                <Typography variant="h6" fontWeight={600}>
                    Edit Profile
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        color: 'text.secondary',
                        '&:hover': { bgcolor: alpha(theme.palette.text.secondary, 0.08) }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider />

            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{ p: 3 }}>
                    <MotionBox
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* Avatar Section */}
                        <Box sx={{ mb: 4, textAlign: 'center' }}>
                            <Box position="relative" display="inline-block">
                                <Avatar
                                    src={user?.avatarUrl}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        border: '3px solid',
                                        borderColor: 'primary.light'
                                    }}
                                >
                                    {user?.name?.[0] || 'U'}
                                </Avatar>
                                <IconButton
                                    component="label"
                                    sx={{
                                        position: 'absolute',
                                        right: -8,
                                        bottom: -8,
                                        bgcolor: 'background.paper',
                                        border: '2px solid',
                                        borderColor: 'primary.light',
                                        '&:hover': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.08)
                                        }
                                    }}
                                >
                                    <input
                                        type="file"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => {
                                            // Handle avatar upload
                                        }}
                                    />
                                    <PhotoCameraIcon color="primary" />
                                </IconButton>
                            </Box>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Full Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label="Email Address"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="title"
                                    label="Job Title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="company"
                                    label="Company"
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    error={formik.touched.company && Boolean(formik.errors.company)}
                                    helperText={formik.touched.company && formik.errors.company}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="location"
                                    label="Location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={formik.touched.location && formik.errors.location}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name="bio"
                                    label="Bio"
                                    value={formik.values.bio}
                                    onChange={formik.handleChange}
                                    error={formik.touched.bio && Boolean(formik.errors.bio)}
                                    helperText={formik.touched.bio && formik.errors.bio}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                    Social Links
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="socialLinks.github"
                                    label="GitHub Profile"
                                    value={formik.values.socialLinks.github}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.socialLinks?.github &&
                                        Boolean(formik.errors.socialLinks?.github)
                                    }
                                    helperText={
                                        formik.touched.socialLinks?.github &&
                                        formik.errors.socialLinks?.github
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="socialLinks.linkedin"
                                    label="LinkedIn Profile"
                                    value={formik.values.socialLinks.linkedin}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.socialLinks?.linkedin &&
                                        Boolean(formik.errors.socialLinks?.linkedin)
                                    }
                                    helperText={
                                        formik.touched.socialLinks?.linkedin &&
                                        formik.errors.socialLinks?.linkedin
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="socialLinks.twitter"
                                    label="Twitter Profile"
                                    value={formik.values.socialLinks.twitter}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.socialLinks?.twitter &&
                                        Boolean(formik.errors.socialLinks?.twitter)
                                    }
                                    helperText={
                                        formik.touched.socialLinks?.twitter &&
                                        formik.errors.socialLinks?.twitter
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MotionBox>
                </DialogContent>

                <Divider />

                <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            px: 3
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        sx={{
                            borderRadius: 2,
                            px: 3,
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'primary.dark'
                            }
                        }}
                    >
                        {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};