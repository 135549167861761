export const lightTheme = {
    palette: {
        primary: {
            main: '#2196F3',
            light: '#64B5F6',
            dark: '#1976D2',
        },
        background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                },
            },
        },
    },
};