import api from '../utils/axios';
import { ENDPOINTS } from '../config/constants';

export const authService = {
    login: async (credentials) => {
        const response = await api.post(ENDPOINTS.AUTH.LOGIN, {
            email: credentials.email.toLowerCase(),
            password: credentials.password
        });
        return response.data;
    },

    register: async (userData) => {
        const response = await api.post(ENDPOINTS.AUTH.REGISTER, {
            email: userData.email.toLowerCase(),
            password: userData.password,
            developerName: userData.developerName.trim()
        });
        return response.data;
    },

    logout: async () => {
        const response = await api.post(ENDPOINTS.AUTH.LOGOUT);
        return response.data;
    }
};