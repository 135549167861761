import React from 'react';
import {
    Paper,
    Typography,
    Box,
    LinearProgress,
    useTheme,
    alpha
} from '@mui/material';
import {
    TrendingUp,
    People,
    Storage,
    Speed
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const MotionPaper = motion(Paper);

const StatCard = ({ stat, index }) => {
    const theme = useTheme();

    return (
        <MotionPaper
            elevation={0}
            sx={{
                p: 3,
                height: '100%',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 3,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[4],
                    borderColor: alpha(stat.color, 0.4)
                }
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: 2,
                        bgcolor: alpha(stat.color, 0.12),
                        color: stat.color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {stat.icon}
                </Box>
                <Box sx={{ ml: 2 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            color: 'text.primary'
                        }}
                    >
                        {stat.value}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            mt: 0.5
                        }}
                    >
                        {stat.title}
                    </Typography>
                </Box>
            </Box>
            <LinearProgress
                variant="determinate"
                value={70}
                sx={{
                    height: 6,
                    borderRadius: 3,
                    bgcolor: alpha(stat.color, 0.12),
                    '& .MuiLinearProgress-bar': {
                        bgcolor: stat.color,
                        borderRadius: 3
                    }
                }}
            />
        </MotionPaper>
    );
};

export const ProjectStats = ({ project }) => {
    const stats = [
        {
            title: 'API Calls',
            value: project.stats?.apiCalls?.toLocaleString() || '0',
            icon: <TrendingUp />,
            color: '#2196f3'
        },
        {
            title: 'Active Users',
            value: project.stats?.activeUsers?.toLocaleString() || '0',
            icon: <People />,
            color: '#4caf50'
        },
        {
            title: 'Storage Used',
            value: `${project.stats?.storageUsed || '0'}GB`,
            icon: <Storage />,
            color: '#ff9800'
        },
        {
            title: 'Avg Response',
            value: `${project.stats?.avgResponseTime || '0'}ms`,
            icon: <Speed />,
            color: '#f44336'
        }
    ];

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: '1fr',
                    sm: '1fr 1fr',
                    md: 'repeat(4, 1fr)'
                },
                gap: 3
            }}
        >
            {stats.map((stat, index) => (
                <StatCard key={stat.title} stat={stat} index={index} />
            ))}
        </Box>
    );
};