import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    FormControlLabel,
    Switch,
    CircularProgress,
    Alert,
    Typography,
    IconButton,
    useTheme,
    alpha,
    Divider
} from '@mui/material';
import {
    Close as CloseIcon,
    Star as StarIcon,
    Security as SecurityIcon,
    Analytics as AnalyticsIcon,
    Store as StoreIcon
} from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Project name is required')
        .min(3, 'Name must be at least 3 characters'),
    description: Yup.string()
        .required('Description is required')
        .min(10, 'Description must be at least 10 characters'),
    settings: Yup.object({
        enableAuth: Yup.boolean(),
        enableAnalytics: Yup.boolean(),
        enableStore: Yup.boolean()
    })
});

const defaultValues = {
    name: '',
    description: '',
    settings: {
        enableAuth: true,
        enableAnalytics: true,
        enableStore: false
    }
};

export const ProjectForm = ({ open, onClose, onSubmit, initialValues, isEdit = false }) => {
    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            ...defaultValues,
            ...initialValues,
            settings: {
                ...defaultValues.settings,
                ...(initialValues?.settings || {})
            }
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setStatus }) => {
            try {
                await onSubmit(values);
                onClose();
            } catch (error) {
                setStatus({ error: error.message });
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    boxShadow: theme.shadows[8]
                }
            }}
        >
            <DialogTitle
                sx={{
                    p: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 1
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StarIcon sx={{ color: 'primary.main' }} />
                    <Typography variant="h6" component="span" fontWeight={600}>
                        {isEdit ? 'Edit Project' : 'Create New Project'}
                    </Typography>
                </Box>
                <IconButton
                    onClick={onClose}
                    size="small"
                    sx={{
                        color: 'text.secondary',
                        '&:hover': {
                            bgcolor: alpha(theme.palette.text.secondary, 0.08)
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider />

            <form onSubmit={formik.handleSubmit}>
                <DialogContent sx={{ p: 3 }}>
                    {formik.status?.error && (
                        <Alert
                            severity="error"
                            sx={{
                                mb: 3,
                                borderRadius: 2
                            }}
                        >
                            {formik.status.error}
                        </Alert>
                    )}

                    <TextField
                        fullWidth
                        name="name"
                        label="Project Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2
                            }
                        }}
                    />

                    <TextField
                        fullWidth
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        margin="normal"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2
                            }
                        }}
                    />

                    <Box sx={{ mt: 4 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Project Settings
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                mt: 1
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1.5,
                                    borderRadius: 2,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <SecurityIcon sx={{ color: 'primary.main', mr: 2 }} />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="settings.enableAuth"
                                            checked={formik.values.settings.enableAuth}
                                            onChange={formik.handleChange}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Box>
                                            <Typography variant="body2">Enable Authentication</Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                Add user authentication to your project
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{ m: 0, flex: 1 }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1.5,
                                    borderRadius: 2,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <AnalyticsIcon sx={{ color: 'primary.main', mr: 2 }} />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="settings.enableAnalytics"
                                            checked={formik.values.settings.enableAnalytics}
                                            onChange={formik.handleChange}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Box>
                                            <Typography variant="body2">Enable Analytics</Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                Track usage and performance metrics
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{ m: 0, flex: 1 }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    p: 1.5,
                                    borderRadius: 2,
                                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                                    border: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <StoreIcon sx={{ color: 'primary.main', mr: 2 }} />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="settings.enableStore"
                                            checked={formik.values.settings.enableStore}
                                            onChange={formik.handleChange}
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Box>
                                            <Typography variant="body2">Enable Store</Typography>
                                            <Typography variant="caption" color="text.secondary">
                                                Add e-commerce capabilities
                                            </Typography>
                                        </Box>
                                    }
                                    sx={{ m: 0, flex: 1 }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>

                <Divider />

                <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        sx={{
                            borderRadius: 2,
                            px: 3
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        sx={{
                            borderRadius: 2,
                            px: 3,
                            minWidth: 120
                        }}
                    >
                        {formik.isSubmitting ? (
                            <CircularProgress size={24} />
                        ) : (
                            isEdit ? 'Save Changes' : 'Create Project'
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};