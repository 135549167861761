import React, { useState } from 'react';
import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Divider,
    Avatar,
    useTheme,
    alpha,
    Tooltip,
    Badge,
    Menu,
    MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
    Dashboard as DashboardIcon,
    Code as ProjectsIcon,
    Brightness4 as DarkModeIcon,
    Brightness7 as LightModeIcon,
    Menu as MenuIcon,
    ChevronRight as ChevronRightIcon,
    Notifications as NotificationsIcon,
    Help as HelpIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    Person as PersonIcon
} from '@mui/icons-material';
import { useTheme as useCustomTheme } from '../../hooks/useTheme';
import { useAuth } from '../../hooks/useAuth';

const drawerWidth = 280;

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        fontSize: 10,
        height: 18,
        minWidth: 18,
        padding: '0 4px'
    },
}));

const LogoText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 800,
    fontSize: '28px',
    color: theme.palette.primary.main,
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    background: `linear-gradient(120deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: 0.9
    }
}));

export const MainLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { mode, toggleTheme } = useCustomTheme();
    const { logout } = useAuth();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [notificationsAnchor, setNotificationsAnchor] = useState(null);
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
        { text: 'Projects', icon: <ProjectsIcon />, path: '/projects' }
    ];

    const handleMenuClick = (path) => {
        navigate(path);
        setMobileOpen(false);
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const isCurrentPath = (path) => location.pathname === path;

    const drawer = (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Logo Section - Now visible in both mobile and desktop */}
            <Box
                sx={{
                    p: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <LogoText>FLUXION</LogoText>
            </Box>

            {/* Navigation Menu */}
            <List sx={{ px: 2, py: 0, flex: 1 }}>
                {menuItems.map((item) => {
                    const isActive = isCurrentPath(item.path);
                    return (
                        <ListItem
                            key={item.text}
                            disablePadding
                            sx={{ mb: 1 }}
                        >
                            <ListItemButton
                                onClick={() => handleMenuClick(item.path)}
                                sx={{
                                    borderRadius: 2,
                                    px: 2,
                                    py: 1.5,
                                    position: 'relative',
                                    ...(isActive && {
                                        bgcolor: alpha(theme.palette.primary.main, 0.08),
                                        '&:hover': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.12),
                                        },
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            left: -8,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            height: '60%',
                                            width: 3,
                                            bgcolor: 'primary.main',
                                            borderRadius: 1
                                        }
                                    })
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 40,
                                        color: isActive ? 'primary.main' : 'text.secondary'
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    sx={{
                                        '& .MuiListItemText-primary': {
                                            fontWeight: isActive ? 600 : 400,
                                            color: isActive ? 'text.primary' : 'text.secondary'
                                        }
                                    }}
                                />
                                {isActive && (
                                    <ChevronRightIcon
                                        sx={{
                                            color: 'primary.main',
                                            opacity: 0.8,
                                            fontSize: 20
                                        }}
                                    />
                                )}
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>

            {/* Theme Toggle */}
            <Box sx={{ p: 2 }}>
                <ListItemButton
                    onClick={toggleTheme}
                    sx={{
                        borderRadius: 2,
                        px: 2,
                        py: 1.5,
                    }}
                >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </ListItemIcon>
                    <ListItemText
                        primary={mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
                        sx={{
                            '& .MuiListItemText-primary': {
                                fontSize: '0.875rem'
                            }
                        }}
                    />
                </ListItemButton>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
            {/* App Bar */}
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
            >
                <Toolbar sx={{ minHeight: { xs: 64, sm: 70 }, px: { xs: 2, sm: 3 } }}>
                    {/* Mobile Menu Icon */}
                    <IconButton
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                            mr: 2,
                            display: { sm: 'none' },
                            color: 'text.primary'
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Spacer */}
                    <Box sx={{ flexGrow: 1 }} />

                    {/* Right Side Actions */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {/* Help */}
                        <Tooltip title="Help">
                            <IconButton
                                sx={{
                                    color: 'text.primary',
                                    display: { xs: 'none', sm: 'flex' }
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>

                        {/* Notifications */}
                        <Tooltip title="Notifications">
                            <IconButton
                                onClick={(e) => setNotificationsAnchor(e.currentTarget)}
                                sx={{ color: 'text.primary' }}
                            >
                                <StyledBadge badgeContent={3}>
                                    <NotificationsIcon />
                                </StyledBadge>
                            </IconButton>
                        </Tooltip>

                        {/* User Menu */}
                        <Tooltip title="Account">
                            <IconButton
                                onClick={(e) => setUserMenuAnchor(e.currentTarget)}
                                sx={{
                                    p: 0.5,
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 2
                                }}
                            >
                                <Avatar
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        bgcolor: 'primary.light',
                                        color: 'primary.main',
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    U
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Navigation Drawer */}
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 }
                }}
            >
                {/* Mobile Drawer */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            border: 'none',
                            bgcolor: 'background.paper',
                            backgroundImage: 'none'
                        },
                    }}
                >
                    {drawer}
                </Drawer>

                {/* Desktop Drawer */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            border: 'none',
                            borderRight: 1,
                            borderColor: 'divider',
                            bgcolor: 'background.paper',
                            backgroundImage: 'none'
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            {/* Notifications Menu */}
            <Menu
                anchorEl={notificationsAnchor}
                open={Boolean(notificationsAnchor)}
                onClose={() => setNotificationsAnchor(null)}
                PaperProps={{
                    sx: {
                        width: 320,
                        maxHeight: 480,
                        mt: 1.5,
                        boxShadow: theme.shadows[4]
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                    <Typography variant="subtitle1" fontWeight={600}>
                        Notifications
                    </Typography>
                </Box>
                {/* Add notification items here */}
            </Menu>

            {/* User Menu */}
            <Menu
                anchorEl={userMenuAnchor}
                open={Boolean(userMenuAnchor)}
                onClose={() => setUserMenuAnchor(null)}
                PaperProps={{
                    sx: {
                        width: 220,
                        mt: 1.5,
                        boxShadow: theme.shadows[4]
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                    <Typography variant="subtitle2" color="text.secondary">
                        Signed in as
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={500}>
                        user@example.com
                    </Typography>
                </Box>
                <MenuItem onClick={() => navigate('/profile')} sx={{ py: 1.5 }}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                </MenuItem>
                <MenuItem onClick={() => navigate('/settings')} sx={{ py: 1.5 }}>
                    <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout} sx={{ py: 1.5 }}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Sign out" />
                </MenuItem>
            </Menu>

            {/* Main Content */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    mt: { xs: '64px', sm: '70px' },
                    minHeight: '100vh',
                    bgcolor: alpha(theme.palette.primary.main, 0.02)
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};