import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Paper,
    useTheme,
    alpha,
    Tooltip,
    IconButton,
    Zoom,
    Divider
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { ProjectList } from '../components/ProjectList';
import { ProjectForm } from '../components/ProjectForm';
import { useProjects } from '../../../app/hooks/useProjects';
import { motion } from 'framer-motion';

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);

export const ProjectsPage = () => {
    const theme = useTheme();
    const { projects, isLoading, error, createProject, fetchProjects } = useProjects();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefresh = async () => {
        setIsRefreshing(true);
        try {
            await fetchProjects();
        } catch (error) {
            console.error('Failed to refresh projects:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    const handleCreateProject = async (data) => {
        try {
            await createProject(data);
            setIsFormOpen(false);
            handleRefresh(); // Yeni proje oluşturulunca listeyi yenile
        } catch (err) {
            console.error('Failed to create project:', err);
        }
    };

    return (
        <MotionContainer
            maxWidth="xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            sx={{
                p: { xs: 2, sm: 3 },
                height: '100%',
                minHeight: '100vh',
                bgcolor: alpha(theme.palette.primary.main, 0.02),
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <MotionPaper
                elevation={0}
                sx={{
                    p: { xs: 2, sm: 3 },
                    borderRadius: 4,
                    bgcolor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
            >
                {/* Header Controls */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                        pb: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        sx={{
                            backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                            backgroundClip: 'text',
                            color: 'transparent',
                            WebkitBackgroundClip: 'text',
                        }}
                    >
                        Projects
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                        <Tooltip
                            title="Refresh Projects"
                            placement="left"
                            TransitionComponent={Zoom}
                        >
                            <IconButton
                                onClick={handleRefresh}
                                disabled={isRefreshing}
                                sx={{
                                    bgcolor: alpha(theme.palette.primary.main, 0.04),
                                    '&:hover': {
                                        bgcolor: alpha(theme.palette.primary.main, 0.08)
                                    },
                                    width: 40,
                                    height: 40,
                                    transition: 'all 0.2s ease-in-out',
                                    animation: isRefreshing ? 'spin 1s linear infinite' : 'none',
                                    '@keyframes spin': {
                                        '0%': {
                                            transform: 'rotate(0deg)',
                                        },
                                        '100%': {
                                            transform: 'rotate(360deg)',
                                        },
                                    },
                                }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title="Create New Project"
                            placement="left"
                            TransitionComponent={Zoom}
                        >
                            <IconButton
                                onClick={() => setIsFormOpen(true)}
                                sx={{
                                    bgcolor: theme.palette.primary.main,
                                    color: 'white',
                                    width: 48,
                                    height: 48,
                                    boxShadow: theme.shadows[4],
                                    transition: 'all 0.2s ease-in-out',
                                    '&:hover': {
                                        bgcolor: theme.palette.primary.dark,
                                        transform: 'scale(1.05)',
                                        boxShadow: theme.shadows[8],
                                    }
                                }}
                            >
                                <AddIcon sx={{ fontSize: 24 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                {/* Project List */}
                <Box
                    sx={{
                        flex: 1,
                        overflow: 'auto',
                        mt: 2
                    }}
                >
                    <ProjectList
                        projects={projects}
                        isLoading={isLoading}
                        error={error}
                    />
                </Box>
            </MotionPaper>

            {/* Project Creation Dialog */}
            <ProjectForm
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSubmit={handleCreateProject}
            />
        </MotionContainer>
    );
};