import { createTheme } from '@mui/material/styles';
import { lightTheme } from './lightTheme';
import { darkTheme } from './darkTheme';

export const getTheme = (mode) => {
    const themeOptions = mode === 'dark' ? darkTheme : lightTheme;

    return createTheme({
        palette: {
            mode,
            ...themeOptions.palette,
        },
        typography: {
            fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
            ...themeOptions.typography,
        },
        components: {
            ...themeOptions.components,
        },
    });
};