import axios from 'axios';

const API_CONFIG = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false
};

const api = axios.create(API_CONFIG);

// Request ID generator
const generateRequestId = () => {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substr(2, 9);
    return `req_${timestamp}_${random}`;
};

api.interceptors.request.use(
    (config) => {
        config.withCredentials = false;
        // Add request ID
        config.headers['X-Request-Id'] = generateRequestId();


        // Add authorization if exists
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Add developer ID if exists
        const developer = localStorage.getItem('developer');
        if (developer) {
            try {
                const developerData = JSON.parse(developer);
                if (developerData.developerId) {
                    config.headers['X-Developer-Id'] = developerData.developerId;
                }
            } catch (error) {
                console.error('Failed to parse developer data');
            }
        }

        console.log('Request:', {
            method: config.method?.toUpperCase(),
            url: config.url,
            data: config.data,
            headers: config.headers
        });

        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('developer');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default api;


