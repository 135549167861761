import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { projectService } from '../services/projectService';

export const useProjects = () => {
    const queryClient = useQueryClient();

    const {
        data: projects,
        isLoading,
        error
    } = useQuery({
        queryKey: ['projects'],
        queryFn: projectService.getProjects,
        select: (data) => {
            if (data?.success && Array.isArray(data.projects)) {
                return {
                    projects: data.projects,
                    count: data.projects.length
                };
            }
            return { projects: [], count: 0 };
        }
    });

    const createProjectMutation = useMutation({
        mutationFn: projectService.createProject,
        onSuccess: () => {
            queryClient.invalidateQueries(['projects']);
        }
    });

    const updateProjectMutation = useMutation({
        mutationFn: ({ id, data }) => projectService.updateProject(id, data),
        onSuccess: () => {
            queryClient.invalidateQueries(['projects']);
        }
    });

    const deleteProjectMutation = useMutation({
        mutationFn: projectService.deleteProject,
        onSuccess: () => {
            queryClient.invalidateQueries(['projects']);
        }
    });

    return {
        projects,
        isLoading,
        error,
        createProject: createProjectMutation.mutateAsync,
        updateProject: updateProjectMutation.mutateAsync,
        deleteProject: deleteProjectMutation.mutateAsync,
    };
};