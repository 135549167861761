export const darkTheme = {
    palette: {
        primary: {
            main: '#90CAF9',
            light: '#B3E5FC',
            dark: '#42A5F5',
        },
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.3)',
                },
            },
        },
    },
};