import React, { createContext, useState, useCallback } from 'react';
import { authService } from '../services/authService';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [developer, setDeveloper] = useState(() => {
        try {
            const saved = localStorage.getItem('developer');
            return saved ? JSON.parse(saved) : null;
        } catch (error) {
            console.error('Error parsing developer data:', error);
            return null;
        }
    });

    const register = useCallback(async (userData) => {
        const response = await authService.register({
            email: userData.email.toLowerCase(),
            password: userData.password,
            developerName: userData.developerName.trim()
        });

        if (response.success) {
            setDeveloper(response.developer);
            localStorage.setItem('token', response.token);
            localStorage.setItem('developer', JSON.stringify(response.developer));
        }
        return response;
    }, []);

    const login = useCallback(async (credentials) => {
        const response = await authService.login({
            email: credentials.email.toLowerCase(),
            password: credentials.password
        });

        if (response.success) {
            setDeveloper(response.developer);
            localStorage.setItem('token', response.token);
            localStorage.setItem('developer', JSON.stringify(response.developer));
        }
        return response;
    }, []);

    const logout = useCallback(async () => {
        try {
            await authService.logout();
        } finally {
            setDeveloper(null);
            localStorage.removeItem('token');
            localStorage.removeItem('developer');
        }
    }, []);

    const value = {
        developer,
        isAuthenticated: !!developer,
        register,
        login,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};