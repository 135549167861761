import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    Box,
    IconButton,
    Divider,
    Tooltip,
    Stack,
    Paper,
    alpha
} from '@mui/material';
import {
    AccessTime as AccessTimeIcon,
    Storage as StorageIcon,
    Speed as SpeedIcon,
    Launch as LaunchIcon,
    ContentCopy as CopyIcon,
    Info as InfoIcon,
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

export const ProjectCard = ({ project }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleViewDetails = () => {
        if (project?.projectId) {
            navigate(`/projects/${project.projectId}`);
        }
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const handleCopyProjectId = async (e) => {
        e.stopPropagation();
        if (project?.projectId) {
            try {
                await navigator.clipboard.writeText(project.projectId);
                enqueueSnackbar('Project ID copied to clipboard', {
                    variant: 'success',
                    autoHideDuration: 2000
                });
            } catch (err) {
                const textArea = document.createElement('textarea');
                textArea.value = project.projectId;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand('copy');
                    enqueueSnackbar('Project ID copied to clipboard', { variant: 'success' });
                } catch (err) {
                    enqueueSnackbar('Failed to copy to clipboard', { variant: 'error' });
                }
                document.body.removeChild(textArea);
            }
        }
    };

    return (
        <Card
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'all 0.3s ease-in-out',
                borderRadius: 2,
                position: 'relative',
                overflow: 'visible',
                bgcolor: 'background.paper',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: (theme) => theme.shadows[8],
                }
            }}
        >
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
                {/* Controls Container */}
                <Stack
                    spacing={0.5}
                    sx={{
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        zIndex: 2,
                        alignItems: 'center'
                    }}
                >
                    {/* Info Button */}
                    <Box
                        sx={{
                            position: 'relative',
                            '&:hover .project-info': {
                                opacity: 1,
                                visibility: 'visible',
                                transform: 'translateY(0)'
                            }
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{
                                width: 28,
                                height: 28,
                                top: -5,
                                backgroundColor: alpha('#000', 0.04),
                                '&:hover': {
                                    backgroundColor: alpha('#000', 0.08)
                                }
                            }}
                        >
                            <InfoIcon sx={{ fontSize: 16 }} />
                        </IconButton>

                        <Box
                            className="project-info"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: '100%',
                                marginRight: '8px',
                                backgroundColor: 'background.paper',
                                boxShadow: 2,
                                borderRadius: 1,
                                padding: 1.5,
                                minWidth: '200px',
                                opacity: 0,
                                visibility: 'hidden',
                                transform: 'translateY(-10px)',
                                transition: 'all 0.2s ease-in-out'
                            }}
                        >
                            <Box
                                onClick={handleCopyProjectId}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover .copy-icon': {
                                        opacity: 1
                                    }
                                }}
                            >
                                <Typography variant="caption" display="block" color="text.secondary" gutterBottom>
                                    Project ID
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ fontFamily: 'monospace' }}
                                    >
                                        {project.projectId}
                                    </Typography>
                                    <CopyIcon 
                                        className="copy-icon"
                                        sx={{ 
                                            fontSize: 14,
                                            opacity: 0.5,
                                            transition: 'opacity 0.2s'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* Status Indicator */}
                    <Box
                        sx={{
                            position: 'relative',
                            '&:hover .status-text': {
                                opacity: 1,
                                transform: 'translateY(0)',
                                visibility: 'visible'
                            }
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{
                                width: 28,
                                height: 28,
                                top: -3,
                                p: 0.5,
                                backgroundColor: project.status === 'ACTIVE' 
                                    ? alpha('#4caf50', 0.12)
                                    : alpha('#000', 0.04),
                                '&:hover': {
                                    backgroundColor: project.status === 'ACTIVE' 
                                        ? alpha('#4caf50', 0.2)
                                        : alpha('#000', 0.08)
                                }
                            }}
                        >
                            <CheckCircleIcon
                                sx={{
                                    fontSize: 16,
                                    color: project.status === 'ACTIVE' ? '#4caf50' : '#9e9e9e'
                                }}
                            />
                        </IconButton>

                        <Paper
                            className="status-text"
                            elevation={2}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '100%',
                                transform: 'translateY(-50%) translateX(-8px)',
                                opacity: 0,
                                visibility: 'hidden',
                                transition: 'all 0.2s ease-in-out',
                                px: 1,
                                py: 0.5,
                                mr: 1,
                                whiteSpace: 'nowrap'
                            }}
                        >
                            <Typography variant="caption">{project.status}</Typography>
                        </Paper>
                    </Box>
                </Stack>

                {/* Title Section */}
                <Box sx={{ p: 2.5 }}>
                    <Typography variant="h6" component="h2" fontWeight="600" sx={{ textAlign: 'left' }}>
                        {project.name}
                    </Typography>
                </Box>

                <Divider sx={{ borderColor: alpha('#000', 0.12), borderWidth: 2 }} />

                {/* Description Section */}
                <Box sx={{ p: 2.5, pb: 2 }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        Description:
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            minHeight: '24px',
                            mb: 1
                        }}
                    >
                        {project.description || 'No description'}
                    </Typography>
                </Box>

                <Divider sx={{ borderColor: alpha('#000', 0.08) }} />

                {/* Project Stats */}
                <Box sx={{ p: 2.5 }}>
                    <Stack spacing={1.5}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <AccessTimeIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                            <Typography variant="body2" color="text.secondary">
                                Created: {formatDate(project.createdAt)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <StorageIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                            <Typography variant="body2" color="text.secondary">
                                Storage: {project.storageUsed || '0 GB'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <SpeedIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                            <Typography variant="body2" color="text.secondary">
                                API Calls: {project.apiCalls || '0'}/month
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </CardContent>

            <Divider sx={{ borderColor: alpha('#000', 0.08) }} />

            {/* Actions Section */}
            <CardActions sx={{ p: 2 }}>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleViewDetails}
                    disabled={!project?.projectId}
                    endIcon={<LaunchIcon />}
                    sx={{
                        borderRadius: 1.5,
                        textTransform: 'none',
                        transition: 'all 0.2s',
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            backgroundColor: '#1565c0'
                        }
                    }}
                >
                    View Details
                </Button>
            </CardActions>
        </Card>
    );
};