import React, { useState } from 'react';
import {
    Container,
    Paper,
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Switch,
    Button,
    Divider,
    useTheme,
    alpha,
    Alert,
    Grid,
    InputAdornment,
    IconButton,
    CircularProgress
} from '@mui/material';
import {
    Notifications as NotificationsIcon,
    Palette as PaletteIcon,
    Security as SecurityIcon,
    Language as LanguageIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Lock as LockIcon
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../../app/hooks/useAuth';
import { useTheme as useCustomTheme } from '../../../app/hooks/useTheme';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);

const passwordValidationSchema = Yup.object({
    currentPassword: Yup.string()
        .required('Current password is required'),
    newPassword: Yup.string()
        .required('New password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Please confirm your password')
});

const SettingSection = ({ icon, title, children }) => {
    const theme = useTheme();

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <Box
                    sx={{
                        p: 1,
                        borderRadius: 2,
                        bgcolor: alpha(theme.palette.primary.main, 0.08),
                        color: 'primary.main'
                    }}
                >
                    {icon}
                </Box>
                <Typography variant="h6" fontWeight={600}>
                    {title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

const PasswordField = ({ formik, name, label }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            fullWidth
            name={name}
            label={label}
            type={showPassword ? 'text' : 'password'}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: 2
                }
            }}
        />
    );
};

export const SettingsPage = () => {
    const theme = useTheme();
    const { mode, toggleTheme } = useCustomTheme();
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [settings, setSettings] = useState({
        emailNotifications: true,
        pushNotifications: false,
        marketingEmails: false,
        language: 'en',
        darkMode: mode === 'dark'
    });

    const passwordFormik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: passwordValidationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                // Change password logic here
                await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated API call
                enqueueSnackbar('Password changed successfully', { variant: 'success' });
                resetForm();
            } catch (error) {
                enqueueSnackbar(error.message || 'Failed to change password', { variant: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const handleSettingsChange = (event) => {
        const { name, checked } = event.target;
        setSettings(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleSaveSettings = () => {
        enqueueSnackbar('Settings saved successfully', { variant: 'success' });
    };

    return (
        <MotionContainer
            maxWidth="lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            sx={{ py: { xs: 2, sm: 3 } }}
        >
            <MotionPaper
                elevation={0}
                sx={{
                    borderRadius: 4,
                    border: '1px solid',
                    borderColor: 'divider',
                    overflow: 'hidden'
                }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
            >
                {/* Header */}
                <Box
                    sx={{
                        p: 3,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8)
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        sx={{
                            backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                            backgroundClip: 'text',
                            color: 'transparent',
                            WebkitBackgroundClip: 'text',
                        }}
                    >
                        Settings
                    </Typography>
                </Box>

                {/* Content */}
                <Box sx={{ p: 3 }}>
                    {/* Security Settings */}
                    <SettingSection icon={<SecurityIcon />} title="Security">
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                border: '1px solid',
                                borderColor: 'divider'
                            }}
                        >
                            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                                Change Password
                            </Typography>
                            <form onSubmit={passwordFormik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <PasswordField
                                            formik={passwordFormik}
                                            name="currentPassword"
                                            label="Current Password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordField
                                            formik={passwordFormik}
                                            name="newPassword"
                                            label="New Password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordField
                                            formik={passwordFormik}
                                            name="confirmPassword"
                                            label="Confirm New Password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={passwordFormik.isSubmitting}
                                            startIcon={passwordFormik.isSubmitting ? <CircularProgress size={20} /> : <LockIcon />}
                                            sx={{
                                                mt: 1,
                                                borderRadius: 2,
                                                textTransform: 'none'
                                            }}
                                        >
                                            {passwordFormik.isSubmitting ? 'Changing Password...' : 'Change Password'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </SettingSection>

                    <Divider sx={{ my: 3 }} />

                    {/* Notifications Section */}
                    <SettingSection icon={<NotificationsIcon />} title="Notifications">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={settings.emailNotifications}
                                            onChange={handleSettingsChange}
                                            name="emailNotifications"
                                        />
                                    }
                                    label="Email Notifications"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={settings.pushNotifications}
                                            onChange={handleSettingsChange}
                                            name="pushNotifications"
                                        />
                                    }
                                    label="Push Notifications"
                                />
                            </Grid>
                        </Grid>
                    </SettingSection>

                    <Divider sx={{ my: 3 }} />

                    {/* Appearance Section */}
                    <SettingSection icon={<PaletteIcon />} title="Appearance">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={mode === 'dark'}
                                    onChange={toggleTheme}
                                    name="darkMode"
                                />
                            }
                            label="Dark Mode"
                        />
                    </SettingSection>

                    <Divider sx={{ my: 3 }} />

                    {/* Language Section */}
                    <SettingSection icon={<LanguageIcon />} title="Language & Region">
                        <TextField
                            select
                            fullWidth
                            label="Language"
                            value={settings.language}
                            onChange={(e) => setSettings(prev => ({ ...prev, language: e.target.value }))}
                            SelectProps={{
                                native: true
                            }}
                            sx={{
                                maxWidth: 400,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2
                                }
                            }}
                        >
                            <option value="en">English</option>
                            <option value="es">Spanish</option>
                            <option value="fr">French</option>
                            <option value="de">German</option>
                        </TextField>
                    </SettingSection>

                    <Box sx={{ mt: 4 }}>
                        <Button
                            variant="contained"
                            onClick={handleSaveSettings}
                            sx={{
                                borderRadius: 2,
                                px: 4,
                                py: 1,
                                textTransform: 'none'
                            }}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </MotionPaper>
        </MotionContainer>
    );
};