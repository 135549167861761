import React from 'react';
import { Box, Typography, useTheme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        alignItems: 'center',
    }
}));

const LogoText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 900,
    fontSize: '45px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    lineHeight: 1,
    background: `linear-gradient(135deg, 
        rgba(255, 255, 255, 0.95) 0%,
        ${alpha(theme.palette.primary.main, 1)} 25%,
        rgba(255, 255, 255, 0.95) 50%,
        ${alpha(theme.palette.primary.main, 1)} 75%,
        rgba(255, 255, 255, 0.95) 100%
    )`,
    backgroundSize: '200% auto',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    animation: 'shine 10s linear infinite',
    '@keyframes shine': {
        to: {
            backgroundPosition: '200% center'
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '55px',
        textAlign: 'center',
        marginTop: '-10px',
    }
}));

const SloganContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

const SloganText = styled(Box)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: '14px',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    lineHeight: 1.6,
    letterSpacing: '0.02em',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px 8px',
    width: '100%',
    '& .word': {
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    '& .highlight': {
        color: theme.palette.primary.light,
        fontSize: '17px',
        fontWeight: 800,
        marginRight: '0px'
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '15px',
    }
}));

const SLOGAN_WORDS = [
    { letter: 'F', word: 'lexible' },
    { letter: 'L', word: 'ifecycle' },
    { letter: 'U', word: 'ser' },
    { letter: 'X', word: 'perience' },
    { letter: 'I', word: 'ntegration' },
    { letter: 'and', word: '', isConnector: true },
    { letter: 'O', word: 'perations' },
    { letter: 'N', word: 'etwork' }
];

export const Logo = () => {
    const theme = useTheme();

    return (
        <LogoContainer>
            <LogoText>FLUXION</LogoText>
            <SloganContainer>
                <SloganText>
                    {SLOGAN_WORDS.map((item, index) => (
                        <span key={index} className="word">
                            {item.isConnector ? (
                                item.letter
                            ) : (
                                <>
                                    <span className="highlight">{item.letter}</span>
                                    {item.word}
                                </>
                            )}
                        </span>
                    ))}
                </SloganText>
            </SloganContainer>
        </LogoContainer>
    );
};