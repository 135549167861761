import { useAuth } from '../../../app/hooks/useAuth';
import React from 'react';
import {
    Container,
    Box,
    Typography,
    useTheme,
    alpha,
    Paper
} from '@mui/material';
import { ProfileInfo } from '../components/ProfileInfo';
import { motion } from 'framer-motion';

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);

export const ProfilePage = () => {
    const theme = useTheme();
    const { user } = useAuth();

    return (
        <MotionContainer
            maxWidth="lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            sx={{
                py: { xs: 2, sm: 3 },
                minHeight: '100vh'
            }}
        >
            <MotionPaper
                elevation={0}
                sx={{
                    p: { xs: 2, sm: 3 },
                    borderRadius: 4,
                    border: '1px solid',
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    overflow: 'hidden'
                }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
            >
                {/* Header */}
                <Box
                    sx={{
                        p: 3,
                        mb: 3,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.8)
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        sx={{
                            backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                            backgroundClip: 'text',
                            color: 'transparent',
                            WebkitBackgroundClip: 'text',
                        }}
                    >
                        My Profile
                    </Typography>
                </Box>

                {/* Content */}
                <Box sx={{ px: 3, pb: 3 }}>
                    <ProfileInfo user={user} />
                </Box>
            </MotionPaper>
        </MotionContainer>
    );
};