import React, { useState } from 'react';
import {
    Box,
    Avatar,
    Typography,
    IconButton,
    Tooltip,
    useTheme,
    alpha,
    Paper,
    Grid,
    Divider,
    Button
} from '@mui/material';
import {
    Email as EmailIcon,
    Edit as EditIcon,
    BusinessCenter as BusinessIcon,
    CalendarToday as CalendarIcon,
    GitHub as GitHubIcon,
    LinkedIn as LinkedInIcon,
    Twitter as TwitterIcon,
    LocationOn as LocationIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { ProfileEditDialog } from './ProfileEditDialog';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const InfoItem = ({ icon, label, value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5 }}>
        {icon}
        <Box>
            <Typography variant="body2" color="text.secondary">
                {label}
            </Typography>
            <Typography variant="body1">
                {value}
            </Typography>
        </Box>
    </Box>
);

export const ProfileInfo = ({ user }) => {
    const theme = useTheme();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    return (
        <>
            <Grid container spacing={4}>
                {/* Left Column - Profile Card */}
                <Grid item xs={12} md={4}>
                    <MotionBox
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                border: '1px solid',
                                borderColor: 'divider',
                                textAlign: 'center'
                            }}
                        >
                            <Box position="relative">
                                <Avatar
                                    src={user?.avatarUrl}
                                    sx={{
                                        width: 120,
                                        height: 120,
                                        mx: 'auto',
                                        mb: 2,
                                        border: '4px solid',
                                        borderColor: 'primary.light'
                                    }}
                                >
                                    {user?.name?.[0] || 'U'}
                                </Avatar>
                                <Tooltip title="Edit Profile">
                                    <IconButton
                                        onClick={() => setIsEditDialogOpen(true)}
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: 0,
                                            bgcolor: 'background.paper',
                                            border: '2px solid',
                                            borderColor: 'primary.light',
                                            '&:hover': {
                                                bgcolor: alpha(theme.palette.primary.main, 0.08)
                                            }
                                        }}
                                    >
                                        <EditIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                            <Typography variant="h6" fontWeight={600} gutterBottom>
                                {user?.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                {user?.title || 'Software Developer'}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: 2,
                                    mt: 2
                                }}
                            >
                                {user?.socialLinks?.github && (
                                    <IconButton href={user.socialLinks.github} target="_blank">
                                        <GitHubIcon />
                                    </IconButton>
                                )}
                                {user?.socialLinks?.linkedin && (
                                    <IconButton href={user.socialLinks.linkedin} target="_blank">
                                        <LinkedInIcon color="primary" />
                                    </IconButton>
                                )}
                                {user?.socialLinks?.twitter && (
                                    <IconButton href={user.socialLinks.twitter} target="_blank">
                                        <TwitterIcon color="info" />
                                    </IconButton>
                                )}
                            </Box>
                        </Paper>
                    </MotionBox>
                </Grid>

                {/* Right Column - Profile Details */}
                <Grid item xs={12} md={8}>
                    <MotionBox
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.1 }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                borderRadius: 3,
                                border: '1px solid',
                                borderColor: 'divider'
                            }}
                        >
                            <Typography variant="h6" fontWeight={600} gutterBottom>
                                Profile Information
                            </Typography>

                            <Box sx={{ mt: 2 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <InfoItem
                                            icon={<EmailIcon color="primary" />}
                                            label="Email"
                                            value={user?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InfoItem
                                            icon={<BusinessIcon color="primary" />}
                                            label="Company"
                                            value={user?.company || 'Not specified'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InfoItem
                                            icon={<LocationIcon color="primary" />}
                                            label="Location"
                                            value={user?.location || 'Not specified'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <InfoItem
                                            icon={<CalendarIcon color="primary" />}
                                            label="Member Since"
                                            value={format(new Date(user?.createdAt || Date.now()), 'MMM dd, yyyy')}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider sx={{ my: 3 }} />

                            <Typography variant="h6" fontWeight={600} gutterBottom>
                                Bio
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                                {user?.bio || 'No bio provided yet.'}
                            </Typography>

                            <Button
                                variant="outlined"
                                onClick={() => setIsEditDialogOpen(true)}
                                sx={{
                                    mt: 2,
                                    borderRadius: 2,
                                    textTransform: 'none'
                                }}
                            >
                                Edit Profile
                            </Button>
                        </Paper>
                    </MotionBox>
                </Grid>
            </Grid>

            <ProfileEditDialog
                open={isEditDialogOpen}
                onClose={() => setIsEditDialogOpen(false)}
                user={user}
            />
        </>
    );
};